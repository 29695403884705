<template>
	<div>
		<Dialog :dialog="statusDialog" :dialog-width="950" @close="closeCall()" persistent>
			<template v-slot:title> Unresolved Domains </template>
			<template v-slot:body>
				<div class="pb-3 mb-5 custom-table mt-2">
					<v-simple-table class="bt-table">
						<thead>
							<tr>
								<th
									v-for="(heading, index) in bottleneck_urls_heading"
									:key="`_heading_${index}`"
									class="p-2 custome-table"
									style="background: #f0f8ff00 !important"
									:width="heading.width"
								>
									{{ heading.thead }}
								</th>
							</tr>
						</thead>

						<tbody v-if="unresolved_data && unresolved_data.length">
							<tr v-for="(row, index) in unresolved_data" :key="index + '_' + 'unresolved_domains'">
								<td class="p-2 border-left-light-grey border-bottom-light-grey custome-table-td">
									<span class="fw-500">{{ getValue(row, "website_urls") }}</span>
								</td>
								<td class="p-2 border-top-light-grey border-bottom-light-grey custome-table-td text-center">
									<v-chip
										class="white--text fw-600"
										fab
										small
										:color="+getValue(row, 'website_urls') != 200 ? 'red' : 'green'"
									>
										{{ getValue(row, "url_status") }}
									</v-chip>
								</td>
								<td class="p-2 border-top-light-grey border-bottom-light-grey custome-table-td text-center">
									<v-btn
										class="white--text mr-2 k-tooltip"
										:loading="pageLoading"
										:disabled="pageLoading"
										color="blue darken-4"
										tile
										@click="fixDomain(row.id)"
										@mouseenter="enter(index, 'enter')"
										@mouseleave="enter(index, 'leave')"
									>
										<v-icon left>mdi-hammer-wrench</v-icon>
										Fix
									</v-btn>
									<v-btn
										class="white--text"
										:loading="pageLoading"
										:disabled="pageLoading"
										color="blue darken-4"
										tile
										@click="tag_Someone(row.customer_service, row.id)"
									>
										<v-icon left>mdi-tag</v-icon>
										Tag
									</v-btn>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr>
								<td colspan="7">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no backlink at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</v-simple-table>
				</div>
			</template>
			<!-- <template v-slot:action> -->
			<!-- <v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					depressed
					color="blue darken-4"
					tile
					@click="updateSEO"
				>
					Save
				</v-btn>
				<v-btn depressed tile v-on:click="closeDialog">Close </v-btn> -->
			<!-- </template> -->
		</Dialog>

		<Dialog :dialog="tagDialog" :dialog-width="600" @close="closeModal">
			<template v-slot:title> Tag Someone </template>
			<template v-slot:body>
				<div class="form-grp-title">
					<small>
						<v-icon size="16">mdi-progress-question</v-icon> Tag someone to resolve domain with valid
						reason.
					</small>
				</div>
				<v-form v-model.trim="approve_reject_form_valid">
					<v-row>
						<v-col md="3">
							<label class="required">Users</label>
						</v-col>
						<v-col md="9">
							<AutoCompleteInput
								hide-details
								multiple
								hideTopMargin
								:items.sync="team_list"
								item-text="display_name"
								item-value="id"
								:id="`related-users`"
								placeholder="Users"
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model="tag.user_id"
								:rules="[vrules.required(tag.user_id, 'Users')]"
							></AutoCompleteInput>
						</v-col>
						<v-col md="3">
							<label class="required">Reason</label>
						</v-col>
						<v-col md="9">
							<TextAreaInput
								hide-details
								dense
								style="margin-top: 0px !important"
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Reject reason"
								:word-limit="200"
								v-model="tag.remark"
								:rules="[vrules.required(tag.remark, 'Reason')]"
							></TextAreaInput>
							<!-- :rules="[vrules.required(statusRemark, 'Reason')]" -->
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading || !approve_reject_form_valid"
					@click="tagSomeone()"
				>
					Tag
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>

<script>
// import TextAreaInput from "@/view/components/TextAreaInput";
// import DatePicker from "@/view/components/DatePicker";
import Dialog from "@/view/components/Dialog";
import { GET, PUT, POST } from "@/core/services/store/request.module";
import { toSafeInteger } from "lodash";
import objectPath from "object-path";
import TextAreaInput from "@/view/components/TextAreaInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import { POST, GET, DELETE } from "@/core/services/store/request.module";

export default {
	name: "Update-SEOProject",
	title: "Update SEO Project",
	props: {
		statusDialog: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		// TextAreaInput,
		// DatePicker,
		Dialog,
		AutoCompleteInput,
		TextAreaInput,
	},
	data() {
		return {
			projectId: null,
			pageLoading: false,
			target_list: [],
			months: [
				{ value: "1", text: "1" },
				{ value: "2", text: "2" },
				{ value: "3", text: "3" },
				{ value: "4", text: "4" },
				{ value: "5", text: "5" },
				{ value: "6", text: "6" },
				{ value: "7", text: "7" },
				{ value: "8", text: "8" },
				{ value: "9", text: "9" },
				{ value: "10", text: "10" },
				{ value: "11", text: "11" },
				{ value: "12", text: "12" },
				{ value: "13", text: "13" },
				{ value: "14", text: "14" },
				{ value: "15", text: "15" },
				{ value: "16", text: "16" },
				{ value: "17", text: "17" },
				{ value: "18", text: "18" },
				{ value: "19", text: "19" },
				{ value: "20", text: "20" },
				{ value: "21", text: "21" },
				{ value: "22", text: "22" },
				{ value: "23", text: "23" },
				{ value: "24", text: "24" },
			],
			tagDialog: false,
			approve_reject_form_valid: true,
			bottleneck_urls_heading: [
				{ thead: "Domain", b_color: "#f0f8ff00", width: "400" },
				{ thead: "Status code", b_color: "#f0f8ff00", width: "100" },
				{ thead: "Action", b_color: "#f0f8ff00", width: "150" },
			],
			tooltip: {
				0: false,
				1: false,
			},
			tag: {
				user_id: null,
				remark: null,
			},
			team_list: [],
			seoId: null,
			urlId: null,
			unresolved_data: null,
		};
	},
	methods: {
		updateSEO() {
			let project_id = this.projectId;
			let seo_id = this.seoId;
			let formData = { ...this.editData };

			this.$store
				.dispatch(PUT, {
					url: `seo-update/${project_id}/${seo_id}`,
					data: formData,
				})
				.then(({ data, status }) => {
					console.log({ data });
					if (status) {
						this.$emit("success");
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getData() {
			this.pageLoading = true;
			let seoId = toSafeInteger(this.seoId);
			this.$store
				.dispatch(GET, {
					url: `seo/${seoId}`,
				})
				.then((data) => {
					this.editData = data;

					this.editData = {
						// id: data.id,
						keyword_monthly: data.keyword_monthly,
						keyword_free: data.keyword_free,
						total_time: data.total_time,
						time_free: data.time_free,
						select_date: data.select_date,
						total_time_end: data.total_time_end,
						time_free_end: data.time_free_end,
						remark: data.remark,
						website_urls: data.customer_urls && data.customer_urls[0].website_urls,
						url_target_google:
							data.customer_urls &&
							data.customer_urls[0].url_target_google &&
							data.customer_urls[0].url_target_google.id,
						other_target_google: data.customer_urls && data.customer_urls[0].other_target_google,
					};

					this.projectId = data.project && data.project.id;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getDomains() {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: "seo/invalid-url",
				})
				.then((data) => {
					// console.log({ data });
					this.unresolved_data = data;
					if (data && Array.isArray(data) && data.length > 0) {
						this.$emit("open", true);
					}

					/* 
					No need close call 1st time.

					else {
						this.$emit("close", true);
					} 
					
					*/
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		closeDialog() {
			this.$emit("close");
		},
		getValue(object, path) {
			return objectPath.get(object, path);
		},
		closeCall() {
			// this.$emit('close', true)
		},
		fixDomain(urlid) {
			// http://127.0.0.1:8000/api/v1/seo/invalid-url/22
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `seo/invalid-url/${urlid}`,
				})
				.then((data) => {
					console.log({ data });
					this.getDomains();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		tag_Someone(seoid, urlid) {
			this.tagDialog = true;
			this.seoId = seoid;
			this.urlId = urlid;
			this.getTeam(seoid);
		},
		closeModal() {
			this.tagDialog = false;
		},
		getTeam(seoid) {
			// http://127.0.0.1:8000/api/v1/seo/seo-team/24
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `seo/seo-team/${seoid}`,
				})
				.then((team) => {
					if (team) {
						let { a_team_lead, sales_owner, team_lead, on_page, off_page, sales_coordinater } = team;
						let _list = [
							a_team_lead,
							sales_owner,
							team_lead,
							on_page,
							off_page,
							sales_coordinater,
						].filter(Boolean);
						this.team_list = _list;
					} else {
						this.team_list = [];
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		tagSomeone() {
			// http://127.0.0.1:8000/api/v1/seo/notifiey/10
			this.pageLoading = true;
			const _this = this;
			const payload = {
				user_id: this.tag.user_id,
				url_id: this.urlId,
				remark: this.tag.remark,
			};
			_this.$store
				.dispatch(POST, {
					url: `seo/notifiey/${this.seoId}`,
					data: payload,
				})
				.then(() => {
					this.closeModal();
					this.getDomains();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		enter(idx, type) {
			if (type == "leave") {
				this.tooltip[idx] = false;
			} else {
				this.tooltip[idx] = true;
			}

			let ref_value = document.querySelector(".k-tooltip");
			if (ref_value) {
				let _rect = ref_value.$el
					? ref_value.$el.getBoundingClientRect()
					: ref_value.getBoundingClientRect();
				// console.log({ _rect });

				let { top, bottom, left, right } = _rect;

				var e = window.event;
				var posX = e.clientX;
				var posY = e.clientY;

				if ((posY > top && posY < bottom) || (posX > left && posX < right)) {
					if (type == "leave") {
						console.log("inactive-tooltip", { top, bottom, left, right, posX, posY });
					} else {
						console.log("active-tooltip", { top, bottom, left, right, posX, posY });
					}
				}
			}
		},
	},
	mounted() {
		// this.getData();
		this.getDomains();
	},
};
</script>
